$sigFont: kepler-std, serif;
$signatureFont: $sigFont;
$sigFontWide: kepler-std, serif;
$deco: trajan-pro-3, serif;
$white: #fff;
$black: #000;
// $red: #a32135;
$red: #0C273A;
$pink:  $red;
$darkRed: #6f1624;
$orange: #f1751b;
$darkOrange: #984911;
// $background-color: #fffaec;
$background-color: #fff;
$purpleRadio: radial-gradient(circle, rgba(106,0,161,0.8925945378151261) 25%, rgba(156,4,95,0.7301295518207283) 100%);

$max-site-width: 840px;
